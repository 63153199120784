import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
// import { FaRegFilePdf } from "react-icons/fa"
import SEO from "../components/SEO"

const SponsorshipInfo = () => {
  return (
    <Layout>
      <SEO title="sponsorship" />
      <section className="sponsorshipInfo__wrapper ">
        <article className="sponsorshipInfo__header">
          <h1 className="mt-28 md:mt-20">NONFNEC Sponsorship</h1>
        </article>
        <article className="bg-white md:mx-5 xl:mx-24 md:my-5 xl:my-10 px-2 md:px-3 xl:px-5 py-5 xl:py-10">
          <div className="max-w-7xl m-auto">
            <div className="">
              <h2>Funders and Sponsors</h2>
              <p className="">
                NOFNEC is a conference run by First Nations, for First Nations.
                Its purpose is to assist First Nation Land Use Planning,
                Environment and Operations and Maintenance professionals from
                more than 80 Northern Ontario First Nation Communities. This
                conference is expected to attract more than 100 attendees and
                will bring together professionals from government, industry,
                business and First Nations.
              </p>
            </div>
            <div className=" mt-10">
              <h2>Why Sponsor NOFNEC?</h2>
              <p className="sponsorshipInfo__content-p">
                Sponsorship is a fantastic opportunity to highlight you and your
                company as a leader in First Nations Environmental Issues to a
                captive and influential audience. Show your customers you
                understand their needs and meet them where they are.
              </p>
              <ul className="sponsorshipInfo__content-p-ul ">
                <li className="sponsorshipInfo__content-p-li">
                  &#9656; Develop and nurture relationships with First Nations
                  Environmental Professionals, Government, and Industry in
                  Northern Ontario
                </li>
                <li className="sponsorshipInfo__content-p-li">
                  &#9656; Show your commitment to environmental protection and
                  First Nation development
                </li>
                <li className="sponsorshipInfo__content-p-li">
                  &#9656; Influence the decision makers
                </li>
                <li className="sponsorshipInfo__content-p-li">
                  &#9656; Forge relationships with First Nation representatives
                </li>
              </ul>
            </div>

            <div className="flex flex-col items-center justify-center xl:flex-row gap-x-5">
              <a
                download
                href="http://tagcreativestrategy.com/NOFNEC/NOFNEC2023_CorporateOpportunies_Digital.pdf"
                className="btn mt-10 bg-nofnecRed border-nofnecRed hover:text-nofnecRed hover:bg-red-100 text-center"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Sponsorhip Packages - opens in new window"
              >
                Sponsorship Packages
              </a>

              <a
                href="https://theadventuregroup.regfox.com/nofnec-2023-ts"
                className="btn mt-10 bg-nofnecYellow border-nofnecYellow hover:text-nofnecYellow hover:bg-yellow-50 text-center"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Exhibitor Registration - opens in new window"
              >
                Exhibitor Registration
              </a>

              <Link to="/contact" className="btn mt-10 text-center">
                Contact Us
              </Link>
            </div>
          </div>
        </article>
      </section>
    </Layout>
  )
}

export default SponsorshipInfo
